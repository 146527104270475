import React from 'react'

export default function Loader({ loading = false }) {
    return (
        loading && (
            <div class="cab-row cab-row-load">
                <div class="cab-row-card">
                    <div class="nft-load">
                        <div class="nft-load-icon"></div>
                    </div>
                </div>
            </div>
        )
    )
}
