import React, { useRef, useState } from 'react';
import logoImg from '../assets/images/nft-blank.png';
import { useAccountStats } from '../stats/useStats';
import { getWeb3 } from '../hooks/connectors';
import { URI_PREFIX, contract, DEFAULT_CHAIN, NFT_SYMBOL, daysLeftFromTimestamp } from '../hooks/constant';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import nftstakeAbi from '../json/nftstakeAbi.json';
import { formatPrice, getContract } from '../hooks/contractHelper';
import { useAccount, useNetwork } from 'wagmi';
import { useEthersSigner } from '../hooks/useEthersProvider';
import Layout from '../component/Layout';
import audioImg from '../assets/images/music-banner.jpg';
import playImg from '../assets/images/play.png';
import pausedImg from '../assets/images/pause.png';



export default function StakedNFTs({ continuousStart, complete }) {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner()
    const [updater, setUpdater] = useState(1);
    const [loading, setLoading] = useState(false);
    let accStats = useAccountStats(updater);
    const [selectUnstake, setSelectUnstake] = useState([]);
    let web3 = getWeb3();
    const [playingAudio, setPlayingAudio] = useState(null);
    const audioRefs = useRef([]);



    const handleSelectNft = async (tokenId) => {

        if (tokenId) {
            let idx = selectUnstake.indexOf(tokenId);
            if (idx !== -1) {
                setSelectUnstake(selectUnstake.filter(x => x !== tokenId))
            }
            else {
                setSelectUnstake([...selectUnstake, tokenId])
            }
        }
        else {
            toast.error('Please try again ! Nft data not found')
        }
    }


    const handleUnstake = async () => {
        if (address) {
            if (chain.id === DEFAULT_CHAIN) {
                if (selectUnstake.length > 0) {
                    try {
                        setLoading(true);
                        let stakeContract = getContract(nftstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS, signer);
                        let tx = await stakeContract._unstakeMany(selectUnstake, { 'from': address });

                        toast.loading('Waiting for confirmation...');
                        var interval = setInterval(async function () {
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss();
                                    toast.success('Your last transaction is success!!');
                                    setLoading(false);
                                    setUpdater(Math.random());
                                }
                                else if (response.status === false) {
                                    toast.error('error ! Your last transaction is failed.');
                                    setLoading(false);
                                }
                                else {
                                    toast.error('error ! something went wrong.');
                                    setLoading(false);
                                }
                            }
                        }, 5000);


                    }
                    catch (err) {
                        toast.error(err.reason ? err.reason : err.message);
                        setLoading(false);
                    }
                }
                else {
                    toast.error('Please select nft first.');
                }
            }
            else {
                toast.error('Please connect to mainnet network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!!');
            setLoading(false);
        }
    }

    const playAudio = (index) => {
        if (playingAudio !== null) {
            audioRefs.current[playingAudio].pause();
        }
        audioRefs.current[index].play();
        setPlayingAudio(index);
    };

    const stopAudio = (index) => {
        audioRefs.current[index].pause();
        audioRefs.current[index].currentTime = 0;
        setPlayingAudio(null);
    };



    return (
        <Layout>
            <div>
                <main className="main-boosts">
                    <div className="cab-page-name d-flex justify-content-between">
                        <div className="h2">Staked NFTs</div>
                        <div className="h4 total-h4">
                            <span>Total Stake - {accStats.stakedNFTData && accStats.stakedNFTData.length ? accStats.stakedNFTData.length : 0} {NFT_SYMBOL}</span>
                            <button type='button' disabled={loading || selectUnstake.length === 0 ? true : false} onClick={() => handleUnstake()} className="btn btn-radio-submit">
                                {!loading ? `UnStake Now(${selectUnstake.length})` : 'Loading...'}
                            </button>
                        </div>
                    </div>
                    <div className="cab-col-wrap">
                        <div className="cab-col cab-main-col">
                            <section id="section-boosts-time" className="cab-row">
                                <div className="cab-row-card">
                                    <div className="cab-card-wrap boosts-card">
                                        <div className="row ">
                                            {address ? (accStats.loading ?
                                                (<div class="cab-row cab-row-load">
                                                    <div class="cab-row-card">
                                                        <div class="nft-load">
                                                            <div class="nft-load-icon"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                ) :
                                                (
                                                    accStats.stakedNFTData && accStats.stakedData && accStats.stakedNFTData.length > 0 && accStats.stakedData.length > 0 && accStats.stakedNFTData.length === accStats.stakedData.length ? (
                                                        accStats.stakedNFTData.map((rowdata, index) => {
                                                            let metadata = rowdata.metadata ? JSON.parse(rowdata.metadata) : []
                                                            let imageIpfs = metadata.image ? metadata.image.replace('ipfs://', URI_PREFIX) : logoImg;
                                                            let tokenId = rowdata.token_id ? rowdata.token_id : 0;
                                                            let stakeInfo = tokenId && accStats.vaultDetails ? accStats.vaultDetails.filter((x) => parseInt(x[0]) === parseInt(tokenId)) : [];
                                                            return (
                                                                <div className="col-12 col-xl-3" key={index} onClick={() => handleSelectNft(tokenId)} >
                                                                    <div className={`block-percent ${selectUnstake.includes(tokenId) ? 'active' : ''}`}>
                                                                        <div className="block-percent-img">

                                                                            {metadata.animation_url ? (
                                                                                metadata.mimeType === "mp3" ? (

                                                                                    <>
                                                                                        <img src={audioImg} className='nft-img' alt="audio" />
                                                                                        <audio ref={el => audioRefs.current[index] = el} src={`${imageIpfs}`} type="audio/mpeg" />
                                                                                        <div style={{
                                                                                            position: 'absolute',
                                                                                            top: '50%',
                                                                                            left: '50%',
                                                                                            transform: 'translate(-50%, -50%)',
                                                                                            zIndex: 10
                                                                                        }}>
                                                                                            {playingAudio === index ? (


                                                                                                <div class="wrapper" onClick={() => stopAudio(index)}>
                                                                                                    <div class="circle pulse"></div>
                                                                                                    <div class="circle">
                                                                                                        <img src={pausedImg} alt='play' height="50px" width="50px" style={{
                                                                                                            position: 'absolute',
                                                                                                            top: '50%',
                                                                                                            left: '50%',
                                                                                                            transform: 'translate(-50%, -50%)',

                                                                                                        }} />
                                                                                                    </div>
                                                                                                </div>

                                                                                            ) : (
                                                                                                <div class="wrapper" onClick={() => playAudio(index)}>
                                                                                                    <div class="circle pulse"></div>
                                                                                                    <div class="circle" >
                                                                                                        <img src={playImg} alt='play' height="24px" width="24px" style={{
                                                                                                            position: 'absolute',
                                                                                                            top: '50%',
                                                                                                            left: '50%',
                                                                                                            transform: 'translate(-50%, -50%)',

                                                                                                        }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </>
                                                                                ) : (
                                                                                    <video controls autoPlay={false}>
                                                                                        <source src={`${imageIpfs}`} type="video/mp4" />
                                                                                    </video>
                                                                                )
                                                                            ) : (
                                                                                <img src={imageIpfs} className="nft-img" alt="" />
                                                                            )}
                                                                        </div>
                                                                        <div className="block-percent-value">
                                                                            <span>{metadata.name ? metadata.name : metadata.title ? metadata.title : 'Not Found'}</span>
                                                                            <div className="card-boost-data">
                                                                                <div className="boost-data-item boost-data-item-main">
                                                                                    <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="#ea8d3c" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>
                                                                                    Token Id :  {tokenId}
                                                                                </div>
                                                                                <div className="boost-data-item boost-data-item-main">
                                                                                    EndDate : {stakeInfo && stakeInfo.length > 0 ? daysLeftFromTimestamp(stakeInfo[0][2] * 1000) : ''} Days
                                                                                </div>
                                                                                <div className="boost-data-item boost-data-item-main">
                                                                                    Reward :  {tokenId && stakeInfo && stakeInfo.length > 0 ? formatPrice(stakeInfo[0][4] / Math.pow(10, 18)) : ''} RICE
                                                                                </div>
                                                                                {parseInt(stakeInfo[0][2]) <= Math.floor(new Date().getTime() / 1000.0) &&
                                                                                    <div className="boost-data-item boost-data-item-danger">
                                                                                        Expire
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })

                                                    ) : (
                                                        <section class="cab-row">
                                                            <div class="cab-row-card">
                                                                <div class="cab-card-wrap your-stake-card">
                                                                    <div class="stake-nft-container-empty">
                                                                        <div>
                                                                            <div class="empty-icon nostake-icon"></div>
                                                                            <div class="empty-title h4">No Staked NFTs Yet</div>
                                                                            <div class="empty-title h7">Start staking your {NFT_SYMBOL}NFTs to earn interest now!</div>
                                                                            <Link to="/" class="btn btn-buy-nft">My NFTs</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>

                                                    )
                                                )
                                            ) : (
                                                <section class="cab-row">
                                                    <div class="cab-row-card">
                                                        <div class="cab-card-wrap your-stake-card">
                                                            <div class="stake-nft-container-empty">
                                                                <div>
                                                                    <div class="empty-icon">

                                                                    </div>
                                                                    <div class="empty-title h4">Connect Wallet</div>
                                                                    <div class="empty-title h7">Start staking your {NFT_SYMBOL} NFT's to earn interest now!</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </main>
            </div>
        </Layout>

    )
}
