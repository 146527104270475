import { useEffect, useState } from "react"
import nftstakeAbi from '../json/nftstakeAbi.json';
import nftAbi from '../json/nft.json';
import tokenAbi from '../json/token.json';
import routerAbi from '../json/pair.json';
import { contract, DECIMALS, DEFAULT_CHAIN } from "../hooks/constant";
import { getStakeNFTDetails, multiCallContractConnect, MulticallContractWeb3 } from "../hooks/contractHelper";
import { getWeb3 } from "../hooks/connectors";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";

import axios from "axios";


export const useHeaderStats = (updater) => {
    let { address } = useAccount();
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        NFTBalance: 0,
        ethBalance: 0
    });


    let nftContract = new web3.eth.Contract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS);
    let mcc = multiCallContractConnect(DEFAULT_CHAIN);


    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await MulticallContractWeb3(

                    [
                        mcc.methods.getEthBalance(address), //0
                        nftContract.methods.balanceOf(address), //1

                    ]

                );

                setStats({
                    ethBalance: data[0] ? data[0] / Math.pow(10, DECIMALS) : 0,
                    NFTBalance: data[1] ? data[1] : 0
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address) {
            fetch();
        }
        else {
            setStats({
                NFTBalance: 0,
                ethBalance: 0
            })
        }

        // eslint-disable-next-line
    }, [updater, address]);

    return stats;
}

export const useAccountStats = (updater) => {
    let { address } = useAccount();
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        stakedNFTData: [],
        stakedData: [],
        loading: true,
        vaultDetails: [],
    });


    let stakeContract = new web3.eth.Contract(nftstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const staker_data = await MulticallContractWeb3([
                    stakeContract.methods.getUserStakedIds(address), //0
                ]);
                console.log(staker_data[0])
                let vaultDetails = [];
                let vaultCalls = [];
                if (staker_data[0] && staker_data[0].length > 0) {
                    staker_data[0].map((ids) => {
                        vaultCalls.push(stakeContract.methods.vault(ids))
                        return true;
                    })

                    vaultDetails = await MulticallContractWeb3(vaultCalls)
                    console.log(vaultDetails)
                }

                let nftData = [];

                if (staker_data[0] && staker_data[0].length > 0) {
                    nftData = await getStakeNFTDetails(staker_data[0]);

                }

                console.log(nftData)

                setStats({
                    stakedNFTData: nftData,
                    stakedData: staker_data[0],
                    loading: false,
                    vaultDetails,
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address) {
            fetch();
        }
        else {
            setStats({
                stakedNFTData: [],
                stakedData: [],
                loading: false,
                vaultDetails: []
            })
        }



        // eslint-disable-next-line
    }, [updater, address]);

    return stats;
}

export const useMintStats = (updater) => {
    const web3 = getWeb3();
    const { address } = useAccount();
    const [stats, setStats] = useState({
        price: 0,
        isApproved: false
    });

    let nftContract = new web3.eth.Contract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS);
    let tokenContract = new web3.eth.Contract(tokenAbi, contract[DEFAULT_CHAIN].TOKEN_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await MulticallContractWeb3(
                    address ? [
                        nftContract.methods.price(),
                        tokenContract.methods.allowance(address, contract[DEFAULT_CHAIN].NFT_ADDRESS),
                    ] : [
                        nftContract.methods.price(),
                    ]
                );

                console.log(data)


                setStats({
                    price: data[0] / Math.pow(10, 18),
                    isApproved: address ? parseFloat(data[1]) > parseFloat(data[0]) : false

                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater, address]);

    return stats;
}


export const useAdminStats = (updater) => {
    const { address } = useAccount();
    let web3 = getWeb3();
    const [stats, setStats] = useState({
        owner: '',
    });

    let nftContract = new web3.eth.Contract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await MulticallContractWeb3(
                    [
                        nftContract.methods.owner()
                    ]
                );


                setStats({
                    owner: data[0]

                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (address) {
            fetch();
        }
        else {
            setStats({
                owner: ''

            })
        }

        // eslint-disable-next-line
    }, [address, updater]);

    return stats;
}

export const useStakeModalStats = (selectedNft = [], stakingPeriod = 0) => {
    let { address } = useAccount();
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        totalReward: [],
        totalSum: 0
    });


    let nftContract = new web3.eth.Contract(nftstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {

                let calls = selectedNft.map((items) => {
                    return nftContract.methods.getPeriodReward(stakingPeriod, items)
                })

                let totalReward = await MulticallContractWeb3(calls);
                console.log(totalReward)

                setStats({
                    totalReward: totalReward,
                    totalSum: totalReward.reduce((accumulator, currentValue) => {
                        return accumulator + (currentValue / Math.pow(10, 18));
                    }, 0)
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (selectedNft) {
            fetch();
        }
        else {
            setStats({
                totalReward: [],
                totalSum: 0
            })
        }

        // eslint-disable-next-line
    }, [address, selectedNft, stakingPeriod]);

    return stats;
}



export const usePriceFetchStats = () => {
    let web3 = getWeb3();
    const [stats, setStats] = useState({
        price: 0,
    });

    let routerContract = new web3.eth.Contract(routerAbi, contract[DEFAULT_CHAIN].PAIR_ADDRESS);

    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await MulticallContractWeb3(
                    [
                        routerContract.methods.getReserves()
                    ]
                );
                let tokenPriceBNB = parseFloat(data[0][0] / Math.pow(10,18)) / parseFloat(data[0][1] / Math.pow(10,18))
                
                const apiUrl = 'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd';
                const response = await axios.get(apiUrl);
                const bnbPrice = response.data.binancecoin.usd;

                setStats({
                    price:   parseFloat(bnbPrice) * parseFloat(tokenPriceBNB)

                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();


        // eslint-disable-next-line
    }, []);

    return stats;
}






