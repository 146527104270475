import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { DEFAULT_CHAIN, URI_PREFIX, api, contract } from '../hooks/constant';
import tokenAbi from '../json/token.json';
import nftAbi from '../json/nft.json';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { useAccount, useNetwork } from 'wagmi';
import { ethers } from 'ethers';
import { getWeb3 } from '../hooks/connectors';
import { toast } from 'react-toastify';
import { getContract } from '../hooks/contractHelper';
import { useHistory } from 'react-router-dom';
import audioImg from '../assets/images/music-banner.jpg';

export default function NftModal({ show, setUpdater, minStats, onHide, selectedNft }) {
    const [loading, setLoading] = useState(false);
    const signer = useEthersSigner()
    const { chain } = useNetwork()
    const { address } = useAccount()
    const web3 = getWeb3();
    const history = useHistory()

    const handleApprove = async () => {
        if (address) {
            if (chain && chain.id === DEFAULT_CHAIN) {
                try {
                    setLoading(true);
                    let tokenContract = getContract(tokenAbi, contract[DEFAULT_CHAIN].TOKEN_ADDRESS, signer);
                    let tx = await tokenContract.approve(contract[DEFAULT_CHAIN].NFT_ADDRESS, ethers.utils.parseEther('100000000000'), { 'from': address });

                    toast.loading('Waiting for confirmation...');
                    var interval = setInterval(async function () {
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss();
                                toast.success('Your last transaction is success!!');
                                setLoading(false);
                                setUpdater(Math.random());

                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                        }
                    }, 5000);


                }
                catch (err) {
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please connect to mainnet network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!!');
            setLoading(false);
        }

    }

    const handleMint = async () => {

        if (address) {
            if (chain && chain.id === DEFAULT_CHAIN) {
                try {
                    setLoading(true);
                    let nftContract = getContract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS, signer);
                    let tx = await nftContract.mint(1, 'ipfs://' + selectedNft.json, { 'from': address });

                    toast.loading('Waiting for confirmation...');
                    var interval = setInterval(async function () {
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                await updateVolume();
                                toast.dismiss();
                                toast.success('Your last transaction is success!!');
                                setLoading(false);
                                setUpdater(Math.random());

                                history.push('/my-nft')
                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                            else {
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdater(Math.random());
                            }
                        }
                    }, 5000);


                }
                catch (err) {
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);
                }
            }
            else {
                toast.error('Please connect to mainnet network !');
                setLoading(false);
            }

        }
        else {
            toast.error('Please Connect Wallet!!');
            setLoading(false);
        }

    }


    async function updateVolume() {
        if (selectedNft.category_id && selectedNft.sub_category_id) {
            await api.post('add_volume', {
                category_id: selectedNft.category_id,
                sub_category_id: selectedNft.sub_category_id,
                volume: minStats.price,
                nft_id : selectedNft.id
            })

        }

        return true
    }

    return (
        <Modal
            show={show}
            onHide={() => onHide()}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Purchase NFT
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='m-5'>
                <ul class="container">
                    <li class="container__item">
                        <div class="stepper">
                            <input class="stepper__input" id="stepper-5-0" name="stepper-5" type="radio" checked="checked" />
                            <div class="stepper__step">
                                <label class="stepper__button" for="stepper-5-0"></label>
                                <p class="stepper__content">Approve</p>
                            </div>
                            <input class="stepper__input" id="stepper-5-1" name="stepper-5" type="radio" checked={minStats.isApproved} />
                            <div class="stepper__step">
                                <label class="stepper__button" for="stepper-5-1"></label>
                                <p class="stepper__content">Confirm</p>
                            </div>
                        </div>
                    </li>
                </ul>
                <div className='mt-5'>
                    <div className='row d-flex justify-content-center align-items-center'>
                        <div className='col-3'>
                            {selectedNft.file_type === "2" ? (
                                <video controls>
                                    <source src={`${URI_PREFIX}${selectedNft?.image}`} className='nft-img-modal' type="video/mp4" />
                                </video>
                            ) : selectedNft.file_type === "3" ? (
                                <img src={audioImg} className='nft-img-modal' alt="images" />
                            ) : (
                                <img src={`${URI_PREFIX}${selectedNft?.image}`} className='nft-img-modal' alt="images" />
                            )}
                            
                        </div>
                        <div className='col-3'>
                            <div>{selectedNft?.name}</div>
                            <div>Price : {minStats.price} RICE</div>
                        </div>
                        <div className='col-12 mt-4'>
                            {minStats.isApproved ? (
                                <button loading={loading} type='button' onClick={() => handleMint()} class="btn btn-radio-submit">
                                    {loading ? 'Loading...' : 'Purchase'}
                                </button>
                            ) : (

                                <button loading={loading} type='button' onClick={() => handleApprove()} class="btn btn-radio-submit">
                                    {loading ? 'Loading...' : 'Approve'}
                                </button>
                            )}
                        </div>
                    </div>

                </div>
            </Modal.Body>
        </Modal>
    );

}
