import React, { useRef } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StakedNFTs from './pages/StakedNFTs';
import LoadingBar from 'react-top-loading-bar'
import Admin from './pages/Admin';
import MyNft from './pages/MyNft';
import Home from './pages/Home';
import AllNFTs from './pages/AllNFTs';
import Category from './pages/Category';
import SubCategory from './pages/SubCategory';


function App() {
  const ref = useRef(null);

  return (
    <div className="App">
      
      <Router>

        <LoadingBar
          color='#00a94d'
          ref={ref}
          height={4}
        />

        <ToastContainer pauseOnFocusLoss={false} />

        <Switch>
          <Route exact path="/">
            <Home continuousStart={() => ref.current.continuousStart()} staticStart={() => ref.current.staticStart()} complete={() => ref.current.complete()} />
          </Route>
          <Route exact path="/allnft">
            <AllNFTs continuousStart={() => ref.current.continuousStart()} staticStart={() => ref.current.staticStart()} complete={() => ref.current.complete()} />
          </Route>
          <Route exact path="/my-nft">
            <MyNft continuousStart={() => ref.current.continuousStart()} staticStart={() => ref.current.staticStart()} complete={() => ref.current.complete()} />
          </Route>
          <Route exact path="/staked">
            <StakedNFTs continuousStart={() => ref.current.continuousStart()} staticStart={() => ref.current.staticStart()} complete={() => ref.current.complete()} />
          </Route>
          <Route exact path="/category/:id">
            <Category continuousStart={() => ref.current.continuousStart()} staticStart={() => ref.current.staticStart()} complete={() => ref.current.complete()} />
          </Route>
          <Route exact path="/sub-category/:id">
            <SubCategory continuousStart={() => ref.current.continuousStart()} staticStart={() => ref.current.staticStart()} complete={() => ref.current.complete()} />
          </Route>
          <Route exact path="/frontend-admin">
            <Admin />
          </Route>
        </Switch>


      </Router>
    </div>
  );
}

export default App;
