import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

export default function Tabbar() {
    const router = useLocation();
    const [menuOpen, setMenuOpen] = useState(false);



    return (
        <div className="tabbar">
            <nav>
                <ul className="tabbar-nav">
                    <li className="tab-item">
                        <Link to="/" className={`${router.pathname === '/' ? 'active' : ''}`}>
                            <span className="icon-wrap">
                                <i className="fa-solid fa-home"></i>
                            </span>
                            <span className="link-name">Home</span>
                        </Link>
                    </li>
                    <li className="tab-item">
                        <Link to="/my-nft" className={`${router.pathname === '/my-nft' ? 'active' : ''}`}>
                            <span className="icon-wrap">
                                <i className="fa-solid fa-desktop"></i>
                            </span>
                            <span className="link-name">My NFTs</span>
                        </Link>
                    </li>
                    <li className="tab-item">
                        <Link to="/staked" className={`${router.pathname === '/staked' ? 'active' : ''}`}>
                            <span className="icon-wrap">
                                <i className="fa-solid fa-box-open"></i>
                            </span>
                            <span className="link-name">Staked NFTs</span>
                        </Link>
                    </li>
                    <li class="tab-item">
                        <button class="tab-more" onClick={() => setMenuOpen(!menuOpen)}>
                            <span class="icon-wrap">
                                <i class="fa fa-light fa-bars"></i>
                            </span>
                            <span class="link-name">More</span>
                        </button>
                    </li>

                </ul>
            </nav>
            {menuOpen &&
                <>
                    <div class="close-tabbar-more"></div>
                    <nav class="tabbar-more">
                        <ul>
                            <li className='my-2'>
                                <a href="https://blackrices.com/" target="_blank" rel="noreferrer">
                                    <span className="link-name">Exchange</span>
                                </a>
                            </li>
                            <li className='my-2'>
                                <a href="#sec">
                                    <span className="link-name">Marketplace</span>
                                </a>
                            </li>
                            <li className='my-2'>
                                <a href={`https://bscscan.com/token/0xD80ED841999751C7c7f087A80699F704fEF5C900`} target="_blank" rel="noreferrer">
                                    <span className="link-name">Contract</span>
                                </a>
                            </li>
                            <li className='my-2'>
                                <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0x5fff7742c714ea69f6439d19a5629876cff0ad3e?t=1717576408792" target="_blank" rel="noreferrer">
                                    <span className="link-name">Chart</span>
                                </a>
                            </li>
                            <li className='my-2'>
                                <a href="https://www.ricesnetwork.com/cbnstaking/" target="_blank" rel="noreferrer">
                                    <span className="link-name">CBN Staking</span>
                                </a>
                            </li>
                            <li className='my-2'>
                                <a href="https://www.ricesnetwork.com/malistaking/" target="_blank" rel="noreferrer">
                                    <span className="link-name">MALI Staking</span>
                                </a>
                            </li>
                            <li className='my-2'>
                                <a href="https://www.ricesnetwork.com/bkrstaking/" target="_blank" rel="noreferrer">
                                    <span className="link-name">BKR Staking</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.ricesnetwork.com/wrstaking/" target="_blank" rel="noreferrer">
                                    <span className="link-name">WR Staking</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </>
            }


        </div>
    )
}
