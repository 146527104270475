import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import logoImg from '../assets/images/logo.png';
import ethLogo from '../assets/images/etherscan-logo.svg';
import bkrLogo from '../assets/images/bkr.png';
import maliLogo from '../assets/images/mali.png';
import cbnLogo from '../assets/images/cbn.png';
import wrLogo from '../assets/images/wr.png';
import { useAdminStats, usePriceFetchStats } from '../stats/useStats';
import { useAccount } from 'wagmi';
import iconImg from '../assets/images/icon.png';
import { formatPrice } from '../hooks/contractHelper';



export default function Sidebar() {
    const router = useLocation();
    const { address } = useAccount();
    const stats = useAdminStats(1);
    const priceStats = usePriceFetchStats()


    return (
        <div className="sidebar">
            <div className="logo-wrap">
                <Link to="/" >
                    <img src={logoImg} className="logo" alt="logo" />
                </Link>
            </div>
            <nav>
                <ul className="sidebar-nav">
                    <li>
                        <Link to="/" className={`${router.pathname === '/' ? 'active' : ''}`}>
                            <span className="icon-wrap">
                                <i className="fa-solid fa-home"></i>
                            </span>
                            <span className="link-name">Home</span>
                        </Link>
                        <Link to="/my-nft" className={`${router.pathname === '/my-nft' ? 'active' : ''}`}>
                            <span className="icon-wrap">
                                <i className="fa-solid fa-desktop"></i>
                            </span>
                            <span className="link-name">My NFTs</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/staked" className={`${router.pathname === '/staked' ? 'active' : ''}`}>
                            <span className="icon-wrap">
                                <i className="fa-solid fa-box-open"></i>
                            </span>
                            <span className="link-name">Staked NFTs</span>
                        </Link>
                    </li>
                    <li>
                        <a href="#sec">
                            <span className="icon-wrap">
                                <i className="fa-solid fa-warehouse"></i>
                            </span>
                            <span className="link-name">Marketplace</span>
                        </a>
                    </li>
                    {address && stats.owner.toLowerCase() === address.toLowerCase() &&
                        <li>
                            <Link to="/admin" className={`${router.pathname === '/admin' ? 'active' : ''}`} >
                                <span className="icon-wrap">
                                    <i class="fa-sharp fa-solid fa-lock"></i>
                                </span>
                                <span className="link-name">Admin</span>
                            </Link>
                        </li>
                    }
                    <li>
                        <a href="https://blackrices.com/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <i class="fa-solid fa-globe"></i>
                            </span>
                            <span className="link-name">Exchange</span>
                        </a>
                    </li>
                    <li>
                        <a href={`https://bscscan.com/token/0xD80ED841999751C7c7f087A80699F704fEF5C900`} target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <img src={ethLogo} alt="eth-logo" width={30} height={30} />
                            </span>
                            <span className="link-name">Contract</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0x5fff7742c714ea69f6439d19a5629876cff0ad3e?t=1717576408792" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <i class="fa-solid fa-globe"></i>
                            </span>
                            <span className="link-name">Chart</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.ricesnetwork.com/cbnstaking/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <img src={cbnLogo} alt="eth-logo" width={30} height={30} />
                            </span>
                            <span className="link-name">CBN Staking</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.ricesnetwork.com/malistaking/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <img src={maliLogo} alt="eth-logo" width={30} height={30} />
                            </span>
                            <span className="link-name">MALI Staking</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.ricesnetwork.com/bkrstaking/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <img src={bkrLogo} alt="eth-logo" width={30} height={30} />
                            </span>
                            <span className="link-name">BKR Staking</span>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.ricesnetwork.com/wrstaking/" target="_blank" rel="noreferrer">
                            <span className="icon-wrap">
                                <img src={wrLogo} alt="eth-logo" width={30} height={30} />
                            </span>
                            <span className="link-name">WR Staking</span>
                        </a>
                    </li>
                </ul>
            </nav>
            <div className='d-flex justify-content-center align-items-center p-1' style={{position : "fixed" , bottom : "10px" , "left" : "15px"}}>
                <a
                    href="https://blackrices.com/swap?outputCurrency=0xD80ED841999751C7c7f087A80699F704fEF5C900"
                    target="_blank"
                    rel="noreferrer"
                    className="d-flex align-items-center text-decoration-none mx-1"
                >
                    <img src={iconImg} style={{ height: '24px', width: '24px' }} alt='banner' />
                    <span className="mx-1" style={{ fontSize: '14px' }}>
                        ${formatPrice(priceStats.price, 3)}
                    </span>
                </a>
                <a
                    href="https://blackrices.com/swap?outputCurrency=0xD80ED841999751C7c7f087A80699F704fEF5C900"
                    target="_blank"
                    rel="noreferrer"
                    className='tf-button buy-button d-flex align-items-center'
                    
                >
                    Buy Now
                    <svg
                        className='ml-1'
                        viewBox="0 0 24 24"
                        width="16"
                        height="16"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{ marginLeft: '8px' }}
                    >
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                        <polyline points="12 5 19 12 12 19"></polyline>
                    </svg>
                </a>
            </div>
        </div>
    )
}
