import { ethers } from "ethers";
import { getWeb3 } from "./connectors";
import { contract, DEFAULT_CHAIN, ALCHEMY_BASE_URL, MORALIS_BASE_URL, MORALIS_API_KEY, MORALIS_CHAIN_NAME } from "./constant";
import multiCallAbi from '../json/multicall.json';
import nftAbi from '../json/nft.json';
import nftstakeAbi from '../json/nftstakeAbi.json';
import axios from "axios";


export const getContract = (abi, address, library) => {
  try {
    return new ethers.Contract(address, abi, library)
  }
  catch {
    return false;
  }
}


export const formatPrice = (num , decimal = 8) => {
  //return parseFloat(num.toFixed(decimals)).toLocaleString();
  return new Intl.NumberFormat('en-US', { maximumSignificantDigits: decimal }).format(num);
}


export const getWeb3Contract = (abi, address) => {
  let web3 = getWeb3(DEFAULT_CHAIN);
  return new web3.eth.Contract(abi, address);
}


export const MulticallContractWeb3 = async (calls = []) => {
  let web3 = getWeb3();
  let multicallAddress = contract[DEFAULT_CHAIN].MULTICALL_ADDRESS;
  const mc = new web3.eth.Contract(multiCallAbi, multicallAddress);
  const callRequests = calls.map((call) => {
    const callData = call.encodeABI();
    return {
      target: call._parent._address,
      callData,
    };
  });

  const { returnData } = await mc.methods
    .aggregate(callRequests)
    .call({});

  let finalData = returnData.map((hex, index) => {
    const types = calls[index]._method.outputs.map((o) =>
      o.internalType !== o.type && o.internalType !== undefined ? o : o.type
    );

    let result = web3.eth.abi.decodeParameters(types, hex);

    delete result.__length__;

    result = Object.values(result);

    return result.length === 1 ? result[0] : result;
  });

  return finalData;
}

export const multiCallContractConnect = (chainId = '') => {
  let multicallAddress = contract[DEFAULT_CHAIN].MULTICALL_ADDRESS;
  let web3 = getWeb3();
  return new web3.eth.Contract(multiCallAbi, multicallAddress);
}

export const getWeb3NftContract = () => {
  let web3 = getWeb3();
  return new web3.eth.Contract(nftAbi, contract[DEFAULT_CHAIN].NFT_ADDRESS);

}

export const getWeb3NftStakingContract = () => {
  let web3 = getWeb3();
  return new web3.eth.Contract(nftstakeAbi, contract[DEFAULT_CHAIN].STAKE_ADDRESS);

}

// export const getAllNFTDetails = async (walletAddress = '') => {
//   if (walletAddress) {
//     let userNftData = [];
//     let pageKey = '';

//     async function* fetchData() {
//       try {
//         while (true) {
//           const fetchURL = !contract[DEFAULT_CHAIN].NFT_ADDRESS
//             ? `${ALCHEMY_BASE_URL}?owner=${walletAddress}&withMetadata=true`
//             : pageKey ? `${ALCHEMY_BASE_URL}?owner=${walletAddress}&&contractAddresses%5B%5D=${[contract[DEFAULT_CHAIN].NFT_ADDRESS]}&pageKey=${pageKey}&withMetadata=true` : `${ALCHEMY_BASE_URL}?owner=${walletAddress}&&contractAddresses%5B%5D=${[contract[DEFAULT_CHAIN].NFT_ADDRESS]}&withMetadata=true`;


//           const config = {
//             method: 'get',
//             url: fetchURL,

//           };
//           let data = await axios(config);
//           if (data.data && data.data !== undefined && data.data.ownedNfts.length > 0) {
//             yield data.data;
//           }
//           if (data.data.pageKey && data.data.pageKey !== '') {
//             pageKey = data.data.pageKey
//           }
//           else {
//             break;
//           }

//         }
//       } finally {

//       }

//     }

//     for await (const nftData of fetchData()) {
//       nftData.ownedNfts.map((value) => {
//         userNftData.push(value);
//         return true;
//       })
//     }

//     return userNftData;
//   }
//   else {
//     return false;
//   }

// }

export const getStakeNFTDetails = async (stakeTokenIds = []) => {
  if (stakeTokenIds.length > 0) {
    let web3 = getWeb3();
    let userNftData = [];
    let pageKey = '';

    async function* fetchData() {
      try {
        while (true) {
          const fetchURL = pageKey ?
            `${MORALIS_BASE_URL}/${contract[DEFAULT_CHAIN].STAKE_ADDRESS}/nft?chain=${MORALIS_CHAIN_NAME}&format=decimal&limit=100&token_addresses=${contract[DEFAULT_CHAIN].NFT_ADDRESS}&normalizeMetadata=false&cursor=${pageKey}` :
            `${MORALIS_BASE_URL}/${contract[DEFAULT_CHAIN].STAKE_ADDRESS}/nft?chain=${MORALIS_CHAIN_NAME}&format=decimal&limit=100&token_addresses=${contract[DEFAULT_CHAIN].NFT_ADDRESS}&normalizeMetadata=false`;


          const config = {
            method: 'get',
            url: fetchURL,
            headers: {
              'accept': 'application/json',
              'X-API-Key': MORALIS_API_KEY,
            },
          };
          let data = await axios(config);

          if (data.data && data.data.result.length > 0) {
            yield data.data.result;
          }
          if (data.data.cursor) {
            pageKey = data.data.cursor;
          } else {
            break;
          }

        }
      } finally {

      }

    }

    for await (const nftData of fetchData()) {
      nftData.map((value) => {
        
        if (value.token_id !== undefined || value.token_id !== '') {
          if (stakeTokenIds.includes(value.token_id)) {
            userNftData.push(value);
          }
        }
        return true;
      })
    }

    return userNftData;
  }
  else {
    return false;
  }
}



export const getAllNFTDetails = async (userAddress = '') => {
  if (userAddress) {
    let userNftData = [];
    let pageKey = '';

    async function* fetchData() {
      try {
        while (true) {
          const fetchURL = pageKey ?
            `${MORALIS_BASE_URL}/${userAddress}/nft?chain=${MORALIS_CHAIN_NAME}&format=decimal&limit=100&token_addresses=${contract[DEFAULT_CHAIN].NFT_ADDRESS}&normalizeMetadata=false&cursor=${pageKey}` :
            `${MORALIS_BASE_URL}/${userAddress}/nft?chain=${MORALIS_CHAIN_NAME}&format=decimal&limit=100&token_addresses=${contract[DEFAULT_CHAIN].NFT_ADDRESS}&normalizeMetadata=false`;

          const config = {
            method: 'get',
            url: fetchURL,
            headers: {
              'accept': 'application/json',
              'X-API-Key': MORALIS_API_KEY,
            },
          };
          let data = await axios(config);

          if (data.data && data.data.result.length > 0) {
            yield data.data.result;
          }
          if (data.data.cursor) {
            pageKey = data.data.cursor;
          } else {
            break;
          }
        }
      } finally {

      }
    }

    for await (const nftData of fetchData()) {
      userNftData = userNftData.concat(nftData); // Concatenate arrays correctly
    }
    return userNftData;
  } else {
    return false;
  }
};




