import React from 'react';
import logoImg from '../assets/images/icon.png';
import { formatPrice } from '../hooks/contractHelper';
import { useHeaderStats } from '../stats/useStats';
import Connect from './Connect';
import { NFT_SYMBOL } from '../hooks/constant';

export default function Header() {
    const commonStats = useHeaderStats(1);
    
    return (
        <header>
            <div className="header-nav justify-content-between">
                <a href="#sec" >
                    <img src={logoImg} className="logo logo-xs router-link-active" alt="logo" />
                </a>
                <div className='container'>
                    <div className='align-items-center d-flex row'>

                        <div className="col-12 col-md-8 d-none d-sm-block" id="coinmarket-widget">
                            <coingecko-coin-price-marquee-widget coin-ids="bitcoin,ethereum,eos,ripple,litecoin" currency="usd" background-color="#0d0f13" locale="en" font-color="#fff"></coingecko-coin-price-marquee-widget>
                        </div>
                        <div className="col-6 col-md-2">
                            <div className="balance-wrap">
                                <button className="header-balance">
                                    <div className="btn-balance-wrap">
                                        <div className="balance-title">Wallet Balance:</div>
                                        <div className="balance active">
                                            <div>
                                                {commonStats.NFTBalance ? formatPrice(commonStats.NFTBalance) : 0} {NFT_SYMBOL}
                                            </div>
                                            <div>
                                                {commonStats.ethBalance ? formatPrice(commonStats.ethBalance) : 0} BNB
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                        <div className="col-6 col-md-2">
                            <Connect />
                        </div>
                    </div>
                </div>

                {/* <div className="col" id="coinmarket-widget" />
                <div class="balance-wrap">

                    <button class="header-balance">
                        <div class="btn-balance-wrap">
                            <div class="balance-title">Wallet Balance:</div>
                            <div class="balance active">
                                <div>{commonStats.NFTBalance ? formatPrice(commonStats.NFTBalance) : 0} {NFT_SYMBOL}</div>
                                <div>{commonStats.ethBalance ? formatPrice(commonStats.ethBalance) : 0} BNB</div>
                            </div>
                        </div>
                    </button>
                </div>


                <div>
                    <Connect />
                </div> */}
            </div>

        </header>
    )
}
