import React, { useEffect, useRef, useState } from 'react';
import logoImg from '../assets/images/nft-blank.png';
import { getAllNFTDetails } from '../hooks/contractHelper';
import { NFT_SYMBOL, URI_PREFIX } from '../hooks/constant';
import StakeModal from '../component/StakeModal';
import { toast } from 'react-toastify';
import { useAccount } from 'wagmi';
import Layout from '../component/Layout';
import audioImg from '../assets/images/music-banner.jpg';
import playImg from '../assets/images/play.png';
import pausedImg from '../assets/images/pause.png';



export default function MyNft({ continuousStart, complete }) {
    const { address } = useAccount();
    const [loading, setLoading] = useState(true);
    const [walletNFTs, setWalletNFTs] = useState([]);
    const [stakeOpen, setStakeOpen] = useState(false);
    const [selectNftData, setSelectNftData] = useState([]);
    const count = useRef(0);
    const [playingAudio, setPlayingAudio] = useState(null);
    const audioRefs = useRef([]);


    useEffect(() => {
        if (address) {
            // staticStart()

            if (count.current === 0) {
                continuousStart();
                getAllNFTDetails(address).then((response) => {
                    setWalletNFTs(response);
                    setLoading(false);
                    complete()
                });
            }

            count.current = count.current + 1;
        }
        // eslint-disable-next-line
    }, [address]);


    const handleSelectNft = async (tokenId) => {

        if (tokenId) {
            let idx = selectNftData.indexOf(tokenId);
            if (idx !== -1) {
                setSelectNftData(selectNftData.filter(x => x !== tokenId))
            }
            else {
                setSelectNftData([...selectNftData, tokenId])
            }
        }
        else {
            toast.error('Please try again ! Nft data not found')
        }
    }

    const handleStakeNow = () => {
        setStakeOpen(true);
    }

    const playAudio = (index) => {
        if (playingAudio !== null) {
            audioRefs.current[playingAudio].pause();
        }
        audioRefs.current[index].play();
        setPlayingAudio(index);
    };

    const stopAudio = (index) => {
        audioRefs.current[index].pause();
        audioRefs.current[index].currentTime = 0;
        setPlayingAudio(null);
    };

    return (
        <Layout>
            <main className="main-boosts">
                <div className="cab-page-name">
                    <div className='d-flex justify-content-between'>
                        <div className="h2">My NFTs</div>
                        <div>
                            <button type='button' onClick={() => handleStakeNow()} disabled={selectNftData.length === 0 ? true : false} className="btn btn-radio-submit">Stake Now({selectNftData.length})</button>
                        </div>
                    </div>
                </div>
                <div className="cab-col-wrap">
                    <div className="cab-col cab-main-col">
                        <section id="section-boosts-time" className="cab-row">
                            <div className="cab-row-card">
                                <div className="cab-card-wrap boosts-card">
                                    <div className="row ">
                                        {address ? (loading ?
                                            (<div class="cab-row cab-row-load">
                                                <div class="cab-row-card">
                                                    <div class="nft-load">
                                                        <div class="nft-load-icon"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            ) :
                                            (
                                                walletNFTs && walletNFTs.length > 0 ? (
                                                    walletNFTs.map((rowdata, index) => {
                                                        let metadata = rowdata.metadata ? JSON.parse(rowdata.metadata) : []
                                                        let imageIpfs = metadata.image ? metadata.image.replace('ipfs://', URI_PREFIX) : logoImg;
                                                        let tokenId = rowdata.token_id ? rowdata.token_id : 0;
                                                        return (
                                                            <div className="col-12 col-xl-3" key={index} onClick={() => handleSelectNft(tokenId)}>
                                                                <div className={`block-percent ${selectNftData.includes(tokenId) ? 'active' : ''}`}>
                                                                    <div className="block-percent-img">
                                                                        {metadata.animation_url ? (
                                                                            metadata.mimeType === "mp3" ? (

                                                                                <>
                                                                                    <img src={audioImg} className='nft-img' alt="audio" />
                                                                                    <audio ref={el => audioRefs.current[index] = el} src={`${imageIpfs}`} type="audio/mpeg" />
                                                                                    <div style={{
                                                                                        position: 'absolute',
                                                                                        top: '50%',
                                                                                        left: '50%',
                                                                                        transform: 'translate(-50%, -50%)',
                                                                                        zIndex: 10
                                                                                    }}>
                                                                                        {playingAudio === index ? (


                                                                                            <div class="wrapper" onClick={() => stopAudio(index)}>
                                                                                                <div class="circle pulse"></div>
                                                                                                <div class="circle">
                                                                                                    <img src={pausedImg} alt='play' height="50px" width="50px" style={{
                                                                                                        position: 'absolute',
                                                                                                        top: '50%',
                                                                                                        left: '50%',
                                                                                                        transform: 'translate(-50%, -50%)',

                                                                                                    }} />
                                                                                                </div>
                                                                                            </div>

                                                                                        ) : (
                                                                                            <div class="wrapper" onClick={() => playAudio(index)}>
                                                                                                <div class="circle pulse"></div>
                                                                                                <div class="circle" >
                                                                                                    <img src={playImg} alt='play' height="24px" width="24px" style={{
                                                                                                        position: 'absolute',
                                                                                                        top: '50%',
                                                                                                        left: '50%',
                                                                                                        transform: 'translate(-50%, -50%)',

                                                                                                    }} />
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </>
                                                                            ) : (
                                                                                <video controls autoPlay={false}>
                                                                                    <source src={`${imageIpfs}`} type="video/mp4" />
                                                                                </video>
                                                                            )
                                                                        ) : (
                                                                            <img src={imageIpfs} className="nft-img" alt="" />
                                                                        )}

                                                                    </div>
                                                                    <div className="block-percent-value">
                                                                        <span>{metadata.name ? metadata.name : rowdata.name ? rowdata.name : 'Not Found'}</span>
                                                                        <div className="card-boost-data">
                                                                            <div className="boost-data-item boost-data-item-main">
                                                                                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="#ea8d3c" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><line x1="4" y1="9" x2="20" y2="9"></line><line x1="4" y1="15" x2="20" y2="15"></line><line x1="10" y1="3" x2="8" y2="21"></line><line x1="16" y1="3" x2="14" y2="21"></line></svg>
                                                                                Token Id :  {tokenId}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })

                                                ) : (
                                                    <section class="cab-row">
                                                        <div class="cab-row-card">
                                                            <div class="cab-card-wrap your-stake-card">
                                                                <div class="stake-nft-container-empty">
                                                                    <div>
                                                                        <div class="empty-icon nostake-icon"></div>
                                                                        <div class="empty-title h4">You Don't have NFTs</div>
                                                                        <div class="empty-title h7">Buy Now {NFT_SYMBOL}NFTs to stake & earn interest now!</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>

                                                )
                                            )
                                        ) : (
                                            <section class="cab-row">
                                                <div class="cab-row-card">
                                                    <div class="cab-card-wrap your-stake-card">
                                                        <div class="stake-nft-container-empty">
                                                            <div>
                                                                <div class="empty-icon">

                                                                </div>
                                                                <div class="empty-title h4">Connect Wallet</div>
                                                                <div class="empty-title h7">Start staking your {NFT_SYMBOL} NFT's to earn interest now!</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        )
                                        }
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                <StakeModal stakeOpen={stakeOpen} setStakeOpen={setStakeOpen} selectNftData={selectNftData} />
            </main>
        </Layout>

    )
}
