import axios from "axios";
import moment from "moment";


export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  56 : {
    MULTICALL_ADDRESS: "0xcf591ce5574258ac4550d96c545e4f3fd49a74ec",
    NFT_ADDRESS: "0xAb71211CFf2c93B34763643Ff94f11E33BCF3213",
    STAKE_ADDRESS: "0xe96528528c4bdc6b2c035d32c1140a3542c3c04a",
    TOKEN_ADDRESS : "0xD80ED841999751C7c7f087A80699F704fEF5C900",
    ROUTER_ADDRESS : "0xC48a3cBAf6Fe0Fb91099FF5ec6e541B50181E382",
    PAIR_ADDRESS : "0x5fff7742c714ea69f6439d19a5629876cff0ad3e",
    WETH : "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
  },
  97 : {
    MULTICALL_ADDRESS: "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18",
    NFT_ADDRESS: "0x4985ad3b69f853643cf6f9b5d427e51594fc621b",
    STAKE_ADDRESS: "0x218d3156b1156ca8309b0fb99d77d96ccfb86b45",
    TOKEN_ADDRESS : "0x255ACaCB162Deb526Df79EFA31282378c6FDbA09",
    ROUTER_ADDRESS : "0x463df7b0a06f3d06c41ba6549787ccf9e4c93d24",
    PAIR_ADDRESS : "0x5fff7742c714ea69f6439d19a5629876cff0ad3e",
    WETH : "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd"
  }
}

export const EXPLORE_URL="https://bscscan.com"
export const DEFAULT_CHAIN = 56;
// export const RPC = "https://data-seed-prebsc-1-s1.bnbchain.org:8545";
export const RPC = "https://bsc-dataseed1.bnbchain.org";
export const DECIMALS = 18;
export const URI_PREFIX = 'https://alchemy.mypinata.cloud/ipfs/';
export const NFT_SYMBOL = "FARM";
export const PROJECT_ID = '27b9720018bf7f8740936e6a6eb28604';
export const MORALIS_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjM3NGQxNGQ3LTIwMDQtNDY2My05ZDY0LWRkZGVkNjNkYzhiYiIsIm9yZ0lkIjoiMzkwMzg5IiwidXNlcklkIjoiNDAxMTQ4IiwidHlwZUlkIjoiNjZmNjkzODYtZjU4NC00ZGEyLTg2M2YtNWFiNzRhZWM4YjZiIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTQ1NDk1MjAsImV4cCI6NDg3MDMwOTUyMH0.cAXhNOD9rXC7NNXYpSaK1EEoD6dc2j_SBuU0HGGgC2g';
export const MORALIS_BASE_URL = "https://deep-index.moralis.io/api/v2.2/";
export const MORALIS_CHAIN_NAME = 'bsc'
// export const BACKEND_URL = "https://mining.devcodesaga.tech";
export const BACKEND_URL = "https://malirices.com/admin";
// export const BACKEND_URL = "http://localhost/ricesnetwork";




export const formatDate = (unixTime) => {
  try {
    let dateString = moment(unixTime).format('lll');
    return dateString;
  }
  catch (err) {
    console.log(err.message);
  }
}

export const api = axios.create({
  baseURL: `${BACKEND_URL}/api`, // Assuming your API routes are prefixed with /api
  headers: {
    'Content-Type': 'application/json',
  }
});

export const stakePeriod = ["3 Month", "6 Month", "12 Month"];


export const daysLeftFromTimestamp = (timestamp) => {
  // Get the current date and time
  const currentDate = new Date();

  // Parse the given timestamp
  const targetDate = new Date(timestamp);

  // Calculate the difference in time (in milliseconds)
  const timeDifference = targetDate - currentDate;

  // Convert the time difference from milliseconds to days
  const millisecondsInADay = 1000 * 60 * 60 * 24;
  const daysLeft = Math.ceil(timeDifference / millisecondsInADay);

  return daysLeft;
}
