import React, { useEffect } from 'react'
import Header from './Header';
import Sidebar from './Sidebar';
import Tabbar from './Tabbar';
import { useLocation } from 'react-router-dom';

export default function Layout({ children }) {
    const location = useLocation()

    useEffect(() => {
        const dropdowns = document.querySelectorAll('.dropdown');

        dropdowns.forEach(dropdown => {
            dropdown.addEventListener('mouseenter', () => {
                const ul = dropdown.querySelector('ul');
                if (ul) {
                    ul.classList.add('show');
                }
            });

            dropdown.addEventListener('mouseleave', () => {
                const ul = dropdown.querySelector('ul');
                if (ul) {
                    ul.classList.remove('show');
                }
            });
        });

        // Cleanup event listeners on unmount
        return () => {
            dropdowns.forEach(dropdown => {
                dropdown.removeEventListener('mouseenter', () => {
                    const ul = dropdown.querySelector('ul');
                    if (ul) {
                        ul.classList.add('show');
                    }
                });

                dropdown.removeEventListener('mouseleave', () => {
                    const ul = dropdown.querySelector('ul');
                    if (ul) {
                        ul.classList.remove('show');
                    }
                });
            });
        };
    }, [location]);

    return (
        <>
            <div className="main-cabinet is_dark">
                <Tabbar />
                <Sidebar />
                <div className="cabinet-container">
                    <Header />
                    {children}
                </div>
            </div>
        </>

    )
}
