import React, { useEffect, useState } from 'react';
import '../assets/allnft/css/style.css';
import Layout from '../component/Layout';
import bannerImg from '../assets/images/banner-collection.jpg';
import { useParams } from 'react-router-dom';
import { BACKEND_URL, api } from '../hooks/constant';
import Loader from '../component/Loader';
import { useHistory } from 'react-router-dom';
import iconImg from '../assets/images/icon.png';

export default function Category() {
    const [categoryData, setCategoryData] = useState([]);
    const [subData, setSubData] = useState([]);
    const { id } = useParams();
    const [loading, setLoading] = useState(true)
    const history = useHistory()
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);

    useEffect(() => {
        async function fetch() {
            const response1 = await api.post('get_category', {
                id: id
            })

            if (response1 && response1.data && response1.data.title) {
                setCategoryData(response1.data)
                setLoading(false)
            }

            const response = await api.post(`get_sub_category?page=${page}`, {
                category_id: id,
                "per_page": 10,
            })

            if (response && response.data && response.data.data) {
                if (page === 1) {
                    setSubData(response.data.data);
                    setLastPage(response.data.last_page);
                }
                else {
                    setSubData(prevData => [...prevData, ...response.data.data]);
                    setLastPage(response.data.last_page);
                }
            }


        }
        if (id) {
            fetch()
        }
    }, [id, page]);


    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handleRedirect = (id) => {
        history.push(`/sub-category/${id}`);
    };


    return (
        <Layout>

            <section class="tf-banner-collection">
                {!loading && categoryData && categoryData.title ? (
                    <div class="tf-container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="banner-collection-inner">
                                    <img src={categoryData.banner ? `${BACKEND_URL}${categoryData.banner}` : bannerImg} alt="banner" class="img-bg" />
                                    <img src={`${BACKEND_URL}${categoryData.image}`} alt="banner" class="img-banner" />
                                </div>

                                <div class="tf-heading style-5">
                                    <h2 class="heading">{categoryData.title}</h2>
                                    <h5>Volume : <img src={iconImg} className='mx-2' style={{ height: "24px", width: "24px", display: "inline-flex" }} />{categoryData.volume}</h5>
                                </div>

                            </div>
                            {subData && subData.length > 0 ? (
                                subData.map((items, key) =>
                                (
                                    <div className='col-6 col-md-4 mt-4' key={key} onClick={() => handleRedirect(items.id)}>
                                        <div class="tf-product-category">
                                            <img src={`${BACKEND_URL}${items.image}`} class="cate-img" />
                                            <div className="category">
                                                <a href="#sec">{items?.title}</a>
                                                <a href="#sec">volume : <img src={iconImg} style={{ height: "14px", width: "14px", display: "inline-flex" }} />{items?.volume}</a>

                                            </div>
                                        </div>
                                    </div>
                                )
                                )
                            ) : (
                                <section class="cab-row">
                                    <div class="cab-row-card">
                                        <div class="cab-card-wrap your-stake-card">
                                            <div class="stake-nft-container-empty">
                                                <div>
                                                    <div class="empty-icon nostake-icon"></div>
                                                    <div class="empty-title h4">No Record Found</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            )}

                            {page < lastPage && (
                                <div className="col-md-12">
                                    <div className="btn-loadmore mt8 wow fadeInUp">
                                        <button onClick={loadMore} className="tf-button loadmore style-4">Load more</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <Loader loading={loading} />
                )}
            </section>
        </Layout>
    )
}
